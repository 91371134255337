import LoginForm from 'components/LoginForm';
import { useDispatch } from 'react-redux';
import ProPageLayout from 'components/ProPageLayout';
import Coupon from 'types/Coupon';
import { variants } from 'utils/amplitudeHelper';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { setUser } from '../../userSlice';
import { setExperiments } from '../../experimentsSlice';

type Props = {
  coupon: Coupon;
};

export default function LoginRoute({ coupon }: Props) {
  const dispatch = useDispatch();
  const languageRegionCode = useLanguageRegionCode();

  return (
    <ProPageLayout>
      <LoginForm
        handleLoginSuccess={async ({ user }) => {
          dispatch(setUser(user));

          const experiments = await variants(user);
          dispatch(setExperiments(experiments));

          const params = new URLSearchParams(window.location.search);

          if (coupon) {
            params.set('k', coupon.code);
          }

          const search = params.toString();

          if (user?.pro) {
            const plusPath = params.has('returnTo') ? params.get('returnTo') : wrapUrlSafe('/plus/welcome', languageRegionCode);
            window.location.assign(plusPath);
          } else {
            window.location.assign(wrapUrlSafe(`/plus/checkout${search ? `?${search}` : ''}`, languageRegionCode));
          }
        }}
        useReactRouter
      />
    </ProPageLayout>
  );
}
