import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import Logo from '@alltrails/shared/denali/components/Logo';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import Button from '@alltrails/shared/denali/components/Button';
import Typography from '@alltrails/shared/denali/components/Typography';
import * as styles from './DiscoverTheAppOfTheYear.module.scss';
import qrCodeSrc from './qr-code-homepage.svg';

const br = () => <br />;

// https://www.figma.com/file/I38YmC36f1JlMJS4pjkqS5/App-of-the-Year?type=design&node-id=1213-39057&mode=dev
export default function AppOfTheYear() {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();

  // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
  const aotyTextSrc = require(`../../../assets/images/aoty-text/aoty-${languageRegionCode}.svg`);

  // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
  const appleLaurel = require(`./apple-laurel-${languageRegionCode}.svg`);

  // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
  const googleLaurel = require(`./google-laurel-${languageRegionCode}.svg`);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.laurels}>
            <img
              alt={intl.formatMessage({ defaultMessage: 'Apple App Store App of the Year' })}
              className={styles.laurel}
              loading="lazy"
              src={appleLaurel}
            />
            <img
              alt={intl.formatMessage({ defaultMessage: 'Google Play Editors’ Choice' })}
              className={styles.laurel}
              loading="lazy"
              src={googleLaurel}
            />
          </div>
          <div className={styles.heading}>
            <FormattedMessage defaultMessage="Discover the 2023<br></br> App of the Year" values={{ br }} />
          </div>
          <div className={styles.download}>
            <img alt="" className={styles.qr} src={qrCodeSrc} />
            <Typography component="div" variant="text300bold">
              <FormattedMessage defaultMessage="Download the app for free" />
            </Typography>
          </div>
          <Button
            className={styles.button}
            linkInfo={{ href: 'https://alltrails.io/UhNxHOHeLGb' }}
            text={<FormattedMessage defaultMessage="Download for free" />}
            testId="download-for-free"
            variant="primary"
          />
        </div>
        <div className={styles.videoContainer}>
          <video autoPlay loop muted playsInline className={styles.video}>
            <source src="https://cdn-assets.alltrails.com/assets/videos/aoty-square-video.mp4" type="video/mp4" />
          </video>
          <img
            alt={intl.formatMessage({ defaultMessage: 'Winner of iPhone App of the Year from the Apple App Store Awards' })}
            className={styles.img}
            loading="lazy"
            src={aotyTextSrc}
          />
          <Logo className={styles.logo} color="light" size="md" variant="logomark" />
        </div>
      </div>
    </div>
  );
}
