import { FormattedMessage } from '@alltrails/shared/react-intl';
import Link from '@alltrails/shared/denali/components/Link';
import Typography from '@alltrails/shared/denali/components/Typography';

const a1 = (chunks: string) => (
  <Link href="/terms" target="_blank" size="sm" testId="tos-a1">
    {chunks}
  </Link>
);

const a2 = (chunks: string) => (
  <Link href="/privacy" target="_blank" size="sm" testId="tos-a2">
    {chunks}
  </Link>
);

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=1571%3A79911
export default function TermsOfService() {
  return (
    <Typography color="secondary" component="div" variant="paragraph100">
      <FormattedMessage
        defaultMessage="By continuing to use AllTrails, you agree to our <a1>Terms of Service</a1> and <a2>Privacy Policy</a2>."
        values={{
          a1,
          a2
        }}
      />
    </Typography>
  );
}
